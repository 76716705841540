import React from "react"
import Typography from "@mui/material/Typography"
import FlexRow from "../components/containers/flexRow"
import FlexColumn from "../components/containers/flexColumn"
import Container from "@mui/material/Container"
import Box from "@mui/material/Box"
import Seo from "components/seo"
import BugReportIcon from "@mui/icons-material/BugReportOutlined"
import DnsIcon from "@mui/icons-material/DnsOutlined"
import WorkOutline from "@mui/icons-material/WorkOutline"
import BackupIcon from "@mui/icons-material/BackupOutlined"
import SearchIcon from "@mui/icons-material/SearchOutlined"
import CodeIcon from "@mui/icons-material/CodeOutlined"
import ContactForm from "components/ContactForm"
import Link from "@mui/material/Link"
import { EnquiriesButton } from "components/buttons/EnquiriesButton"

/*----------------------------------------------------------------
*******************************

Services page for Slick Blue Dragon

*******************************
----------------------------------------------------------------*/

const services = () => {
  const iconStyles = {
    fontSize: "3rem",
    marginBottom: "0.25em",
  }
  return (
    <>
      <Container sx={{ maxWidth: "90%" }}>
        <FlexRow>
          <FlexColumn
            sx={{
              alignItems: "center",
              paddingTop: "0.9em",
              paddingBottom: "1.9em",
            }}
          >
            <Typography
              variant="h1"
              sx={{
                "&.MuiTypography-h1": {
                  flex: "1 1 100%",
                  fontSize: "2rem",
                  fontWeight: "500",
                  paddingTop: "0.5em",
                  maxWidth: "100%",
                },
                width: "100%",
              }}
            >
              The Website Development Services We Can Provide...
            </Typography>
            <Typography variant="body1">
              Websites involve a variety of tasks and is by no means a one-off
              event so there are a variety of services we can discuss depending
              on what you need.
            </Typography>
          </FlexColumn>
        </FlexRow>
      </Container>
      <Box
        sx={{
          backgroundColor: "primary.offWhite",
          paddingTop: "1.75em",
          paddingBottom: "1.75em",
          borderColor: "primary.accentLight",
          borderTopStyle: "inset",
          borderBottomStyle: "inset",
          borderTopWidth: "0.75em",
          borderBottomWidth: "0.75em",
        }}
      >
        <Container
          sx={{ maxWidth: "90%", backgroundColor: "primary.offWhite" }}
        >
          <FlexRow
            sx={{
              flexWrap: { xs: "wrap", md: "nowrap" },
            }}
          >
            <FlexColumn sx={{ alignItems: "center" }}>
              <BugReportIcon sx={{ ...iconStyles }}></BugReportIcon>
              <Typography variant="body1">
                Whether or not we built your website, we can make an evaluation
                and quote for fixing an issue or making a small tweak.
              </Typography>
            </FlexColumn>
            <FlexColumn sx={{ alignItems: "center" }}>
              <DnsIcon sx={{ ...iconStyles }} />
              <Typography variant="body1">
                Website hosting varies and I can evaluate if a move would be
                beneficial for you.
              </Typography>
            </FlexColumn>
            <FlexColumn sx={{ alignItems: "center" }}>
              <WorkOutline sx={{ ...iconStyles }} />
              <Typography variant="body1">
                Websites can be a simple showcase for your business or more.
                Perhaps a storefront for selling online or a community.{" "}
              </Typography>
            </FlexColumn>
          </FlexRow>
        </Container>
      </Box>
      <Container sx={{ maxWidth: "90%" }}>
        <FlexRow
          sx={{
            flexWrap: { xs: "wrap", md: "nowrap" },
            paddingTop: "3em",
            paddingBottom: "3em",
          }}
        >
          <FlexColumn sx={{ alignItems: "center" }}>
            <BackupIcon sx={{ ...iconStyles }}></BackupIcon>
            <Typography variant="body1">
              Backups are often neglected but can be critical when something
              goes wrong and more so in the event of a hack.
            </Typography>
          </FlexColumn>
          <FlexColumn sx={{ alignItems: "center" }}>
            <SearchIcon sx={{ ...iconStyles }} />
            <Typography variant="body1">
              For your website to have the best chance of being found by
              customers we can look at fixing errors and data tweaks for Google
              and other search engines.
            </Typography>
          </FlexColumn>
          <FlexColumn sx={{ alignItems: "center" }}>
            <CodeIcon sx={{ ...iconStyles }} />
            <Typography variant="body1">
              Depending on your budget we can look at the degree of
              customisation. A tailored solution can future proof your business.
            </Typography>
          </FlexColumn>
        </FlexRow>
      </Container>
      <Box
        sx={{
          backgroundColor: "secondary.main",
          paddingTop: "2.75em",
          paddingBottom: "1.37em",
        }}
      >
        <Container
          maxWidth="false"
          sx={{
            maxWidth: "700px",
            width: { xs: "95%", md: "80%" },
            border: "0.25em solid black",
            borderRadius: "0.5em",
            backgroundColor: "primary.offWhite",
            boxShadow: "0.5em 0.5em 0.75em",
            padding: "1em 5%",
          }}
        >
          <ContactForm />
        </Container>
      </Box>
      <Box sx={{ backgroundColor: "secondary.main" }}>
        <Container maxWidth="false" sx={{ maxWidth: "700px", width: "100%" }}>
          <FlexRow sx={{ justifyContent: "center", paddingTop: "0.5em" }}>
            <Link underline="none" sx={{}} href="/contact">
              <EnquiriesButton />
            </Link>
          </FlexRow>
        </Container>
      </Box>
    </>
  )
}

export default services

export function Head() {
  return (
    <Seo
      title="Freelance web development services provided by Slick Blue Dragon."
      description="Slick Blue Dragon provide all you need for your website from WordPress, custom development and the relevant maintenance plans for peace of mind."
    />
  )
}
